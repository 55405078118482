<template>
  <div id="live-chat-placeholder" ref="live-agent" class="live-agent"></div>
</template>

<script lang="ts">
import Vue from "vue";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";

const logger = loggerFactory({
  tags: [logTags.Layer.Component],
});

type Data = {
  window: Window & typeof globalThis;
  baseContentUrl: string;
  baseUrl: string;
  buttonIds: string;
  deploymentId: string;
  envUrl: string;
  eswDevName: string;
  eswConfigDevName: string;
  gslbBaseUrl: string;
  orgId: string;
  snapinUrl: string;
};

interface SalesforceButton {
  region: string;
  buttonId: string;
}

export default Vue.extend({
  name: "LiveAgent",
  data(): Data {
    return {
      window,
      baseContentUrl: this.$config.public.liveAgentBaseContentUrl,
      baseUrl: this.$config.public.liveAgentBaseUrl,
      buttonIds: this.$config.public.liveAgentButtonIds,
      deploymentId: this.$config.public.liveAgentDeploymentId,
      envUrl: this.$config.public.liveAgentEnvUrl,
      eswDevName: this.$config.public.liveAgentEswDevName,
      eswConfigDevName: this.$config.public.liveAgentEswConfigDevName,
      gslbBaseUrl: this.$config.public.liveAgentGslbBaseUrl,
      orgId: this.$config.public.liveAgentOrgId,
      snapinUrl: this.$config.public.liveAgentSnapinUrl,
    };
  },
  mounted() {
    const currentLocale = this.$i18n.locale;
    if (currentLocale === "au") {
      this.eswDevName = this.$config.public.liveAgentEswDevNameAustralia;
      this.eswConfigDevName =
        this.$config.public.liveAgentEswConfigDevNameAustralia;
    }

    if (this.$route?.query?.performance_test) {
      // no need to load live chat during performance tests such as lighthouse
      return;
    }
    if (this.getAgentButtonIdByRegion(this.buttonIds) === undefined) {
      return;
    }
    const script = document.createElement("script");
    script.setAttribute("src", this.envUrl + "/embeddedservice/5.0/esw.min.js");
    script.onload = () => {
      this.init();
      this.portal();
      this.liveChatStoreTrigger();
    };
    document.body.appendChild(script);
  },
  methods: {
    init() {
      if (!("embedded_svc" in this.window)) {
        return;
      }

      window.embedded_svc.settings.targetElement = this.$refs["live-agent"];
      window.embedded_svc.settings.defaultMinimizedText = "Live Chat";
      window.embedded_svc.settings.enabledFeatures = ["LiveAgent"];
      window.embedded_svc.settings.entryFeature = "LiveAgent";

      window.embedded_svc.settings.extraPrechatInfo = [
        {
          entityFieldMaps: [
            {
              doCreate: true,
              doFind: true,
              fieldName: "LastName",
              isExactMatch: true,
              label: "Last Name",
            },
            {
              doCreate: true,
              doFind: true,
              fieldName: "FirstName",
              isExactMatch: true,
              label: "First Name",
            },
            {
              doCreate: true,
              doFind: true,
              fieldName: "Email",
              isExactMatch: true,
              label: "Email",
            },
          ],
          entityName: "Contact",
          saveToTranscript: "Contact",
          showOnCreate: true,
        },
      ];

      window.embedded_svc.settings.extraPrechatFormDetails = [
        {
          label: "Source",
          value: "Website Enquiry",
          transcriptFields: [],
          displayToAgent: true,
        },
        {
          label: "Referring Site",
          value: window.location.href,
          transcriptFields: ["ReferrerUri"],
          displayToAgent: true,
        },
        {
          label: "Last Name",
          transcriptFields: ["Pre_Chat_Last_Name__c"],
          displayToAgent: true,
        },
        {
          label: "First Name",
          transcriptFields: ["Pre_Chat_First_Name__c"],
          displayToAgent: true,
        },
        {
          label: "Email",
          transcriptFields: ["Pre_Chat_Email__c"],
          displayToAgent: true,
        },
        {
          label: "Booking Reference",
          transcriptFields: ["Pre_Chat_Booking_Reference__c"],
          displayToAgent: true,
        },
        {
          label: "Reason for Enquiry",
          transcriptFields: ["Pre_Chat_Qualifier_Reason__c"],
          displayToAgent: true,
        },
        {
          label: "What describes you the best?",
          transcriptFields: ["User_Type__c"],
          displayToAgent: true,
        },
      ];

      window.embedded_svc.init(
        this.envUrl,
        this.snapinUrl,
        this.gslbBaseUrl,
        this.orgId,
        this.eswConfigDevName,
        {
          baseLiveAgentContentURL: this.baseContentUrl,
          deploymentId: this.deploymentId,
          buttonId: this.getAgentButtonIdByRegion(this.buttonIds),
          baseLiveAgentURL: this.baseUrl,
          eswLiveAgentDevName: this.eswDevName,
        }
      );
    },
    portal() {
      if (!("embedded_svc" in this.window)) {
        return;
      }
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("portal") !== "live-chat") {
        return;
      }
      window.embedded_svc.settings.prepopulatedPrechatFields = {
        FirstName: urlParams.get("firstname") ?? "",
        LastName: urlParams.get("lastname") ?? "",
        Email: urlParams.get("email") ?? "",
      };

      this.openChat();
    },
    openChat() {
      window.embedded_svc.initLightningOut();
    },
    getAgentButtonIdByRegion(butonIdsConfig: string): string | undefined {
      const buttons: SalesforceButton[] = butonIdsConfig
        .split(",")
        .map((button: string) => {
          return {
            region: button.split("-")[0],
            buttonId: button.split("-")[1],
          };
        });

      return buttons.find((b) => b.region.toLowerCase() === this.$i18n.locale)
        ?.buttonId;
    },
    liveChatStoreTrigger() {
      window.embedded_svc.addEventHandler(
        "onSettingsCallCompleted",
        (data: { isAgentAvailable: boolean | undefined }) => {
          this.updateLiveChatStore(!!data.isAgentAvailable).catch((err) =>
            logger.error(
              "Some exception in LiveAgent: onSettingsCallCompleted",
              { error: err }
            )
          );
        }
      );
      window.embedded_svc.addEventHandler(
        "onAvailability",
        (data: { isAgentAvailable: boolean | undefined }) => {
          this.updateLiveChatStore(!!data.isAgentAvailable).catch((err) =>
            logger.error("Some exception in LiveAgent: onAvailability", {
              error: err,
            })
          );
        }
      );
    },
    async updateLiveChatStore(status: boolean) {
      await this.$store.dispatch("liveChat/setLiveChatStatus", status);
    },
  },
});
</script>

<style lang="scss">
@import "../../components/LiveAgent/live-agent";
</style>
